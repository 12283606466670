import { Item } from '@/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useAuth from '@/contexts/AuthContext';
import { checkOfferPossibleFn, getCurrentOfferFn } from '@/queries/offer';
import { useEffect } from 'react';

const useOffers = (prod?: Item, isWatching = false, enabled = true) => {
    const { user } = useAuth();
    const queryClient = useQueryClient();

    const checkOfferPossibleFnEnabled = prod?.check_offers_possible && enabled;
    const {
        data: offerPossible,
        isLoading: isLoadingOfferPossible,
        refetch: refetchOfferPossible,
    } = useQuery({
        queryKey: ['checkOfferPossibleFn', prod?.id, checkOfferPossibleFnEnabled],
        queryFn: () =>
            checkOfferPossibleFnEnabled ? checkOfferPossibleFn(prod?.id) : undefined,
        enabled: checkOfferPossibleFnEnabled,
        refetchOnMount: checkOfferPossibleFnEnabled,
        refetchOnWindowFocus: checkOfferPossibleFnEnabled && isWatching,
    });

    const getCurrentOfferFnEnabled = enabled && prod?.check_offers_possible && !prod?.current_offer_transaction;

    const {
        data: currentOffer,
        isLoading: isLoadingCurrentOffer,
        refetch: refetchLoadingOffer,
    } = useQuery({
        queryKey: ['getCurrentOfferFn', prod?.id, getCurrentOfferFnEnabled],
        queryFn: () =>
            getCurrentOfferFnEnabled ? getCurrentOfferFn(prod?.id) : undefined,
        enabled: getCurrentOfferFnEnabled,
        refetchOnMount: getCurrentOfferFnEnabled,
        refetchOnWindowFocus: getCurrentOfferFnEnabled && isWatching,
    });

    useEffect(() => {
        if (user && prod) {
            refetchOfferPossible({});
            refetchLoadingOffer();
        }
    }, [user, prod, refetchOfferPossible, refetchLoadingOffer]);

    const invalidateQueries = () => {
        queryClient.invalidateQueries({
            predicate: (query) => {
                return query.queryKey[0] === 'getItemQueryFn' && query.queryKey[1] === prod?.slug;
            }
        });
        queryClient.invalidateQueries({
            predicate: (query) => {
                return query.queryKey[0] === 'getCurrentOfferFn' && query.queryKey[1] === prod?.id;
            }
        });
        queryClient.invalidateQueries({
            predicate: (query) => {
                return query.queryKey[0] === 'checkOfferPossibleFn' && query.queryKey[1] === prod?.id;
            }
        });
        queryClient.invalidateQueries({ queryKey: ['getOwnerLatestOfferPerItem'] });
        queryClient.invalidateQueries({ queryKey: ['getUserLatestOfferPerItem'] });
    };

    return {
        offerPossible: offerPossible?.offers_possible,
        currentOffer: prod?.current_offer_transaction?.offer || currentOffer,
        offerPossibleIsLoading: isLoadingOfferPossible,
        offerIsLoading: isLoadingCurrentOffer,
        refetchOffer: refetchLoadingOffer,
        invalidateQueries
    };
};

export default useOffers;
